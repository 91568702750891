<template>
  <el-input
    v-bind:v-no-space="this.apply !== 'logOff'"
    class="input-password"
    :type="pwdType"
    v-model="normalInputObj.inpValue"
    ref="inputs"
    :maxlength="20"
    :placeholder="placeholder"
  >
    <template v-if="dir === 'rtl'" #prefix>
      <el-image
        class="el-img"
        @click="showPwdVisible"
        :src="pwdSuffixImg"
        fit="contain"
      >
      </el-image>
    </template>
    <template v-if="dir !== 'rtl'" #suffix>
      <el-image
        class="el-img"
        @click="showPwdVisible"
        :src="pwdSuffixImg"
        fit="contain"
      >
      </el-image>
    </template>
  </el-input>
</template>

<script>
const eyeOn = require("@/assets/images/ic_16_eyeon@4x.png");
const eyeOff = require("@/assets/images/ic_16_eyeoff@4x.png");

export default {
  data() {
    return {
      pwdVisible: false,
      normalInputObj: this.inpObj,
      apply: this.$commonFunc.getUrlParams()?.apply,
      dir: document.dir,
    };
  },
  props: {
    inpObj: {
      type: Object,
      default: function () {
        return {
          inpValue: "", // 输入框值
          autoFocus: false, // 是否自动聚焦
        };
      },
    },
    placeholder: {
      type: String,
      default: "Enter password",
    },
  },
  computed: {
    pwdType: function () {
      return this.pwdVisible ? "text" : "password";
    },
    pwdSuffixImg: function () {
      return this.pwdVisible ? eyeOn : eyeOff;
    },
  },
  mounted() {
    // 输入框自动聚焦，但在IOS下不能生效（ios为了保护安全，禁止自动获取输入框focus方法）
    // 参考：https://blog.csdn.net/weixin_42437043/article/details/105250966
    if (!this.inpObj.autoFocus) return;
    this.$refs.inputs.focus();
  },
  methods: {
    showPwdVisible() {
      this.pwdVisible = !this.pwdVisible;
    },
  },
};
</script>

<style lang="less" scoped>
.input-password {
  font-size: 16px;

  ::v-deep .el-input__inner {
    height: 48px;
    border: none;
    font-weight: 600;
    padding: 0;
    border-bottom: 1px solid #e5e5e5;
    background-color: transparent;
    line-height: 20px;
  }

  ::v-deep input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
  // [dir="rtl"] {
  //   left: 35px;
  //   right: auto;
  // }
  // ::v-deep .el-input__suffix[dir="rtl"] {
  //   left: 12px !important;
  //   right: auto !important;
  // }
  // ::v-deep .rtl .el-input__suffix {
  //   left: 12px !important;
  //   right: auto !important;
  // }
  ::v-deep .el-img {
    width: 30px;
    height: 48px;
    padding: 15px 0;
    img {
      width: 30px;
      height: 18px;
    }
  }
}
</style>
