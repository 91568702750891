<template>
  <div class="login-container" v-loading="loading">
    <div class="switchBox">
      <!-- 语言切换 -->
      <div class="switch" @click="switchLang">
        {{ langList[tabIndex]?.desc }}
        <img class="switch-icon" src="@/assets/images/alexa/ic_thr.png" />
      </div>
      <!-- 选择国家入口 -->
      <area-port-index
        :portInfor="portInfor"
        v-if="this.appConfig[this.appId].region"
      />
    </div>

    <!-- logo -->
    <img
      class="logo"
      :src="images.logo"
      v-if="this.appConfig[this.appId].appLogo"
    />
    <h3 class="title">{{ title }}</h3>

    <!-- 选择国家入口 -->

    <!-- <area-port :portInfor="portInfor" /> -->

    <!-- 输入框 -->
    <!-- class="login-input-t" -->
    <div class="mt-45">
      <input-normal
        :inpObj="emailInpObj"
        :placeholder="langAlexaLogin.account_input_holder"
      />
      <input-password
        class="login-input-b"
        :inpObj="passwordInpObj"
        :placeholder="langAlexaLogin.passwordPlh"
      />
    </div>

    <!-- 忘记密码 && 注册 -->
    <div class="forget-line" v-if="apply !== 'logOff'">
      <div class="forgotPwdButton" @click="forgotPassword">
        {{ langAlexaLogin.forgotPsw }}
      </div>
      <div class="createAccountButton" @click="createAccount">
        {{ langAlexaLogin.register }}
      </div>
    </div>

    <!-- 登录 -->
    <el-button
      v-dbClick
      @click="login"
      class="login-button"
      :class="{ 'el-disabled': !loginFlag }"
      type="primary"
      round
    >
      {{ langAlexaLogin.login }}
    </el-button>

    <!-- 相关协议 -->
    <agree-policy @checkFn="checkFn" />

    <!-- 授权介绍相关 -->
    <confirm-google v-if="scope === 'GOOGLE' && apply !== 'logOff'" />
    <confirm-common v-if="scope === 'IFTTT' || scope === 'YANDEX'" />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import AgreePolicy from "./components/agree-policy.vue";
import InputNormal from "./components/input-normal.vue";
import InputPassword from "./components/input-password.vue";
import confirmGoogle from "./components/confirm-google.vue";
import confirmCommon from "./components/confirm-common.vue";
import areaPortIndex from "./components/area-port-index.vue";
import {
  alexaLogin,
  alexaLoginPort,
  googleGetSecretKey,
  googleLogin,
} from "@/api/alexaLogin.js";
import { setCookie } from "@/utils/CookieUtils";
import { APP_CONFIG } from "@/utils/appConstant";
import { LANGS_ARR } from "./language";
// import { language } from "./language";
const oemLogo = require("@/assets/images/oem_logo.png");
const oem_logo_1101 = require("@/assets/images/oem_logo_1101.png");
const oem_logo_1102 = require("@/assets/images/oem_logo_1102.png");
const oem_logo_1104 = require("@/assets/images/oem_logo_1104.png");
const oem_logo_1105 = require("@/assets/images/oem_logo_1105.png");
const oem_logo_1124 = require("@/assets/images/oem_logo_1124.png");
const oem_logo_1125 = require("@/assets/images/oem_logo_1125.png");
const oem_logo_1126 = require("@/assets/images/oem_logo_1126.png");

const logo_arr = {
  1020: oemLogo,
  1101: oem_logo_1101,
  1102: oem_logo_1102,
  1104: oem_logo_1104,
  1105: oem_logo_1105,
  1124: oem_logo_1124,
  1125: oem_logo_1125,
  1126: oem_logo_1126,
  "6ebc56bbfc714951b0d2390d20b526a0": oemLogo,
  b86c15ee40c54edab63b24605030de41: oem_logo_1101,
  "2c9cb2ac7ba14035870dbd388ff55750": oem_logo_1102,
  "746f99bcdda14029a3794937ee68eb7d": oem_logo_1104,
  b0a6ca0b7c9749f785c1b5430342b6de: oem_logo_1105,
  c494a9d749a4413e98893f1ae12ea2c1: oem_logo_1124,
  "44ffbdf9fd3346e0b8f64c5ef66b95eb": oem_logo_1125,
  "15253dd6061042a8a7c550e3e08cd7a5": oem_logo_1126,
};

export default {
  props: ["appId"],
  data() {
    return {
      loading: APP_CONFIG[this.appId].region,
      appConfig: APP_CONFIG,
      username: "",
      password: "",
      title: APP_CONFIG[this.appId || 1020].appName,
      images: {
        logo: logo_arr[this.appId || 1020],
        fit: "contain",
      },
      // port: "美国",
      portInforNor: {
        //默认值
        area: "United States (the)",
        phoneCode: 1,
        countryCode: "US",
      },
      emailInpObj: {
        // email 输入框
        inpValue: "",
        inputType: "text",
        autoFocus: true,
      },
      langs: LANGS_ARR[this.appId || 1020] || LANGS_ARR[1020],
      passwordInpObj: {
        // 输入密码
        inpValue: "",
        autoFocus: false,
      },
      checkedFlag: false, // 是否勾选协议
      apply: this.$commonFunc.getUrlParams()?.apply,
    };
  },
  components: {
    AgreePolicy,
    InputPassword,
    InputNormal,
    areaPortIndex,
    confirmGoogle,
    confirmCommon,
  },
  computed: {
    ...mapState("alexaLogin", [
      "tabIndex",
      "langList",
      "portList",
      "portIndex",
      "languagePackage",
    ]),
    // 账号密码是否为空
    loginFlag() {
      const emaiLength = this.emailInpObj.inpValue.length;
      const passwordLength = this.passwordInpObj.inpValue.length;
      return emaiLength && passwordLength && this.checkedFlag;
    },
    // 中英文切换 tabIndex: 0 英文、 1 中文
    langAlexaLogin() {
      console.log("this.tabIndex", this.languagePackage, [this.tabIndex]);
      return this.languagePackage[this.tabIndex];
    },

    portInfor() {
      return this.portList.length > 0
        ? this.portList[this.portIndex]
        : this.portInforNor;
    },
    scope() {
      let _scope = "";
      switch (this.$commonFunc.getUrlChannel()) {
        case "alexa":
          _scope = "ALEXA";
          break;
        case "google":
          _scope = "GOOGLE";
          break;
        case "yandex":
          ``;
          _scope = "YANDEX";
          break;
        case "ifttt":
          _scope = "IFTTT";
          break;
        default:
          break;
      }
      return _scope;
    },
  },
  created() {
    //更换title和logo
    console.log(this.appId, "appId");
    if (+this.tabIndex === 10) {
      document.documentElement.dir = "rtl";
    } else {
      document.documentElement.dir = "ltr";
    }
    this.setLangList(this.appId);
    this.setLanguagePackage(this.appId);
  },
  mounted() {
    // if (this.scope === "YANDEX") {
    //   //yandex默认俄语
    //   this.setTabIndex("3");
    // }
    if (this.appConfig[this.appId].region) {
      this.getPort();
    }
  },
  methods: {
    ...mapMutations("alexaLogin", [
      "setPortList",
      "setTabIndex",
      "setLangList",
      "setLanguagePackage",
    ]),
    forgotPassword() {
      this.$router.push(
        `/${this.$commonFunc.getUrlChannelAppID()}/reset-password${this.$commonFunc.linkUrlParams()}`
      );
    },
    createAccount() {
      this.$router.push(
        `/${this.$commonFunc.getUrlChannelAppID()}/create-account${this.$commonFunc.linkUrlParams()}`
      );
    },
    login() {
      // 勾选协议
      if (!this.checkedFlag)
        return this.$toast(this.languagePackage[this.tabIndex].agreeToast);
      // 判断邮箱格式是否正确
      let loginAccount = this.emailInpObj.inpValue.trim();
      let loginAccountFlag = Boolean;
      if (loginAccount.indexOf("@") != "-1") {
        loginAccountFlag = this.$commonFunc.verifyEmail(loginAccount);
      } else {
        loginAccountFlag = this.$commonFunc.verifyPhoneNo(loginAccount);
      }
      if (!loginAccountFlag)
        return this.$toast(this.languagePackage[this.tabIndex].emailToast);
      // 判断密码格式是否正确
      const password = this.passwordInpObj.inpValue;
      // 20231128-事业部密码规则不一致，去掉密码校验
      // const passwordFlag = this.$commonFunc.verifyPassword(password);
      // if (!passwordFlag)
      //   return this.$toast(this.languagePackage[this.tabIndex].pswToast);
      // 登录请求
      const urlFlag = JSON.stringify(this.$commonFunc.getUrlParams()) === "{}"; // 判断普通链接，获取对象是否为空
      const params = urlFlag
        ? this.$route.query // hash 模式链接
        : this.$commonFunc.getUrlDecodeURIParams(); // 普通模式链接
      const initParams = urlFlag
        ? this.$route.query // hash 模式链接
        : this.$commonFunc.getUrlParams(); // 普通模式链接
      const { redirect_uri, client_id } = params;
      const { state } = initParams;
      loginAccount =
        loginAccount.indexOf("@") != "-1"
          ? loginAccount
          : `${this.portInfor.phoneCode}${loginAccount}`;
      console.log("params", params);
      //如果url的apply字段时logOff注销的时候，则跳转注销公告
      if (this.apply === "logOff") {
        const language = this.langs[this.tabIndex];
        //跳转注销公告

        if (APP_CONFIG[this.appId].newOEM) {
          // googleGetSecretKey({ appCode: this.appId })
          //   .then((res) => {
          googleLogin({
            account: loginAccount,
            oemPassword: this.$commonFunc.encryptOldOEMPassword(password),
            //处理微清加密逻辑
            password: this.$commonFunc.encryptPassword(password, this.appId),
            redirect_uri,
            appCode: this.appId,
            scope: this.scope,
            language,
          })
            .then((res) => {
              //跳转【注销通告】
              window.sessionStorage.setItem("loginKey", res?.loginKey);
              this.$router.push(
                `/${this.$commonFunc.getUrlChannelAppID()}/logOffAgreement${this.$commonFunc.linkUrlParams(
                  {
                    initParams: {
                      account: this.$commonFunc.encrypt(loginAccount),
                    },
                  }
                )}`
              );
            })
            .catch((err) => {
              const code = err?.data?.code;
              const msg =
                {
                  14001: this.languagePackage[this.tabIndex].emailToast, // 用户不存在
                  14004: this.languagePackage[this.tabIndex].loginError, // 密码错误
                }[code] || this.languagePackage[this.tabIndex].netToast; // 其他场景，网络异常
              const errMsg = err?.data.msg;
              this.$toast(errMsg || msg);
            });
          // })
          // .catch((err) => {
          //   console.log(err, "err");
          // });
        } else {
          googleLogin({
            account: loginAccount,
            password: this.$commonFunc.encryptOldOEMPassword(password, "pws"),
            redirect_uri,
            appCode: this.appId,
            scope: this.scope,
          })
            .then((res) => {
              //跳转【注销通告】
              window.sessionStorage.setItem("loginKey", res?.loginKey);
              this.$router.push(
                `/${this.$commonFunc.getUrlChannelAppID()}/logOffAgreement${this.$commonFunc.linkUrlParams(
                  {
                    initParams: {
                      account: this.$commonFunc.encrypt(loginAccount),
                    },
                  }
                )}`
              );
            })
            .catch((err) => {
              const code = err?.data?.code;
              const msg =
                {
                  14001: this.languagePackage[this.tabIndex].emailToast, // 用户不存在
                  14004: this.languagePackage[this.tabIndex].loginError, // 密码错误
                }[code] || this.languagePackage[this.tabIndex].netToast; // 其他场景，网络异常
              const errMsg = err?.data.msg;
              this.$toast(errMsg || msg);
            });
        }
        return;
      }
      alexaLogin({
        loginAccount,
        password: this.$commonFunc.encryptPassword(password),
        redirect_uri,
        client_id,
        scope: this.scope,
      })
        .then((res) => {
          if (this.$commonFunc.getUrlChannel() === "alexa") {
            //?authorizationCode=${ res.code }&state=${state}&redirect_uri=${redirect_uri}
            this.$router.push(
              `/${this.$commonFunc.getUrlChannelAppID()}/confirm-bind${this.$commonFunc.linkUrlParams(
                {
                  initParams: {
                    authorizationCode: res.code,
                    state,
                    redirect_uri,
                  },
                }
              )}`
            );
          } else {
            const query = {
              authorizationCode: res.code,
              state: state,
              redirect_uri: redirect_uri,
            };
            this.$commonFunc.linkRouter(query);
          }
        })
        .catch((err) => {
          const code = err?.data?.code;
          const msg =
            {
              14001: this.languagePackage[this.tabIndex].emailToast, // 用户不存在
              14004: this.languagePackage[this.tabIndex].loginError, // 密码错误
            }[code] || this.languagePackage[this.tabIndex].netToast; // 其他场景，网络异常
          const errMsg = err?.data.msg;
          this.$toast(errMsg || msg);
        });
    },
    checkFn(val) {
      this.checkedFlag = val;
    },
    switchLang() {
      this.$router.push(
        `/${this.$commonFunc.getUrlChannelAppID()}/lang-switch${this.$commonFunc.linkUrlParams()}`
      );
    },
    getPort() {
      // console.log("getPort", this.tabIndex, this.langs);
      const language = this.langs[this.tabIndex];
      alexaLoginPort({ language })
        .then((res) => {
          res.list.length > 0 && this.setPortList(res.list);
          //每个接口都得国家码 除了获取国家码接口
          setCookie("countryCode", this.portInfor.countryCode);
          setCookie("digitRule", this.portInfor.digitRule);
          this.loading = false;
          // this.portInfor = this.portList.length > 0 ? this.portList[this.portIndex] : this.portInfor
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.login-container {
  padding: 0 30px;
  padding-top: 19px;
  background-image: url("../../../assets/images/alexa/bg@3x.png");
  background-size: cover;
  height: 100vh;
  .title {
    text-align: center;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    margin-top: 14px;
  }
}
.switchBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .switch {
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    cursor: pointer;
    // text-align: left;
    &-icon {
      width: 9px;
      height: 9px;
      display: inline-block;
      margin-left: 6px;
      margin-bottom: 2px;
    }
  }
}

.logo {
  width: 58px;
  height: 58px;
  margin: 0 auto;
}

.login-input-t {
  margin-top: 60px;
}

.login-input-b {
  margin-top: 10px;
}

::v-deep .el-input__inner {
  height: 48px;
  border: none;
  padding: 0;
  font-size: 16px;
  border-bottom: 1px solid #e5e5e5;
}

::v-deep input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.mt-45 {
  margin-top: 45px;
}

.forget-line {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .forgotPwdButton {
    flex: 1;
    font-size: 14px;
    // font-weight: bold;
    color: silver;
  }

  .createAccountButton {
    font-size: 14px;
    // font-weight: bold;
    color: dodgerblue;
  }
}

.login-button {
  width: 100%;
  margin-top: 50px;
  height: 44px;
  border: none;

  &.el-disabled {
    background-color: #a0cfff;
  }
}
</style>
<style lang="less">
.el-button--primary {
  color: #fff;
  background-color: #267aff;
  border-color: #267aff;
}
</style>
