<template>
  <div class="privacyCheckBox">
    <!-- 勾选 -->
    <label class="check-label" for="privacy" @click="checkFn">
      <div :class="[agreePrivacy ? 'check-bg' : 'no-check-bg']">
        <img
          v-show="agreePrivacy"
          class="img"
          src="@/assets/images/alexa/ic_16_hook@3x.png"
        />
      </div>
      <input type="checkbox" v-model="privacyChecked" id="privacy" />
    </label>
    <!-- 描述 -->
    <div class="desc-r">
      <p class="privacyLabel">
        {{ language.wordAgree }}
        <span class="privacyButton" @click="privacyPolicy">
          &nbsp;{{ language.policy }}
        </span>
        <span class="privacyAnd">&nbsp;{{ language.and }}&nbsp;</span>
        <span class="privacyButton" @click="softwareLicense">
          {{ language.license }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { APP_CONFIG } from "@/utils/appConstant";
import { mapState } from "vuex";
export default {
  data() {
    return {
      appId: this.$commonFunc.getUrlAppId(),
      agreePrivacy: false,
      privacyChecked: false,
    };
  },
  computed: {
    ...mapState("alexaLogin", ["tabIndex", "languagePackage"]),
    language() {
      return this.languagePackage[this.tabIndex];
    },
    selectedLang() {
      return this.$commonFunc.getApiLanguage(this.tabIndex);
    },
  },
  methods: {
    checkFn() {
      this.agreePrivacy = !this.agreePrivacy;
      this.$emit("checkFn", this.agreePrivacy);
    },
    privacyPolicy() {
      const lang = APP_CONFIG[this.appId].langs[this.selectedLang] || "en_US";
      const langEn = APP_CONFIG[this.appId].langs[this.selectedLang] || "en";
      switch (APP_CONFIG[this.appId].policyType) {
        case 0:
          window.location.href = APP_CONFIG[this.appId].policyLink; //只有英文不替换
          return;
        case 1:
          //&lang=en处理
          window.location.href = APP_CONFIG[this.appId].policyLink.replace(
            "lang=en",
            `lang=${langEn}`
          );
          return;
        case 2:
          //dollin的
          window.location.href = APP_CONFIG[this.appId].policyLink.replace(
            "en_US.html",
            `${lang}.html`
          );
          return;
        default:
          window.location.href = APP_CONFIG[this.appId].policyLink.replace(
            "en_US.html",
            `${lang}.html`
          );
          return;
      }
    },
    softwareLicense() {
      const lang = APP_CONFIG[this.appId].langs[this.selectedLang] || "en_US";
      const langEn = APP_CONFIG[this.appId].langs[this.selectedLang] || "en";
      switch (APP_CONFIG[this.appId].licenseType) {
        case 0:
          window.location.href = APP_CONFIG[this.appId].licenseLink; //只有英文不替换
          return;
        case 1:
          //&lang=en处理
          window.location.href = APP_CONFIG[this.appId].licenseLink.replace(
            "lang=en",
            `lang=${langEn}`
          );
          return;
        case 2:
          //dollin的
          window.location.href = APP_CONFIG[this.appId].licenseLink.replace(
            "en_US.html",
            `${lang}.html`
          );
          return;
        case 3:
          ///en.html处理
          window.location.href = APP_CONFIG[this.appId].licenseLink.replace(
            "en.html",
            `${langEn}.html`
          );
          return;
        default:
          window.location.href = APP_CONFIG[this.appId].licenseLink.replace(
            "en_US.html",
            `${lang}.html`
          );
          return;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.privacyCheckBox {
  display: flex;
  margin-top: 20px;
  line-height: 16px;
  font-size: 12px;
  .check-label {
    position: relative;
    width: 18px;
    height: 18px;
    .check-bg,
    .no-check-bg {
      box-sizing: border-box;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;

      width: 100%;
      height: 100%;
    }
    .check-bg {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #267aff;
      .img {
        width: 18px;
        height: 18px;
      }
    }
    .no-check-bg {
      border: 1px solid #999999;
    }
    input {
      opacity: 0;
      width: 100%;
      height: 100%;
    }
  }
  .desc-r {
    flex: 1;
  }
}

.privacyLabel {
  margin-left: 8px;
  margin-right: 8px;
  color: silver;
}

.privacyButton {
  cursor: pointer;
  color: black;
}
#privacy {
  margin: 0;
}
</style>
