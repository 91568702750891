<template>
  <div class="confirmGoogleBox">
    <h3>{{ langBinds.titleGoogle1 }}</h3>
    <!-- <h4 class="p">{{ langBinds.descGoogle1 }}</h4>
    <h4 class="p circle">{{ langBinds.descGoogle2 }}</h4> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
// import { langBind } from "../language";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("alexaLogin", ["tabIndex", "languagePackage"]),
    langBinds() {
      return this.languagePackage[this.tabIndex];
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.confirmGoogleBox {
  margin-top: 30px;
  padding: 16px 12px;
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  h3 {
    font-size: 13px;
    color: #212121;
    margin-bottom: 9px;
    font-weight: 400;
  }
  .p {
    font-size: 12px;
    color: #6c757d;
    font-weight: 400;
    line-height: 17px;
  }
  .circle {
    margin-bottom: 3px;
    position: relative;
    padding-left: 10px;
    &:before {
      content: "";
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #999999;
      top: 5px;
      left: 0px;
    }
  }
}
</style>
